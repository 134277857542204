<template>
    <v-dialog v-model="dialog.show" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Side Task</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :disabled="!valid" @click="save()">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>Adding Side Task</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Fields</v-list-item-title>
              <v-list-item-subtitle>Please make sure to fill all required fields</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list three-line subheader>
          <v-subheader>Add Side Task Form</v-subheader>
          <v-list-item>
            <v-text-field 
              v-model="task.going_to"
              :error-messages="!validate(task.going_to, true )? ['[going to] is required ']: []"
              type="text"
              label="Going To?"
              color="primary"
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="task.meeting_who"
              :error-messages="!validate(task.meeting_who, true )? ['[meeting who] is required ']: []"
              type="text"
              label="Meeting who?"
              color="primary"
            />
          </v-list-item>
          <v-list-item>
            <v-combobox
              v-model="task.product"
              :error-messages="!validate(task.product, true )? ['[product] is required ']: []"
              :items="products"
              label="Product"
              color="primary"
            >
            </v-combobox>
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="task.doing_what"
              :error-messages="!validate(task.doing_what, true )? ['[Doing what] is required ']: []"
              type="text"
              label="Doing what?"
              color="primary"
            />
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="task.notes"
              :error-messages="!validate(task.notes, false, 1)? ['[Notes] is not valids']: []"
              type="text"
              label="Notes"
              color="primary"
            />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
</template>
<script>
import Api from '@/api/api/index.js'
export default {
  methods: {
    save() {
      if (this.valid) {
        this.dialog.show = false;
        this.$emit("add", JSON.parse(JSON.stringify(this.task)));
      }
    },
    clear() {
      this.task.going_to = "";
      this.task.meeting_who = "";
      this.task.doing_what = "";
      this.task.notes = "";
      this.task.product = "";
    },
    validate(value,required=false, length=3){
      return (typeof value == "string" && value?.length >= length) || (!required && value?.length == 0);
    },
    async fetch_products(){
      try {
        const products = (await Api.post('task/fetch/products_names')).data.result.products
        this.$set(this, 'products', products)
      } catch (err) {
        let notification = {
          msg:
            err.response?.data?.error?.msg || "Error, please try again later",
          color: "error"
        };
        this.$store.dispatch("user/notify", notification);
      }
    }
  },
  async created(){
    await this.fetch_products()
  },
  watch: {
    show(val) {
      if (val == true) {
        this.clear();
      }
    }
  },
  data() {
    return {
      products:[],
      task: {
        going_to: "",
        meeting_who: "",
        doing_what: "",
        notes: "",
        product:"",
      }
    };
  },
  props: {
    dialog: {
      type: Object,
      default: ()=>({show:false})
    }
  },
  computed: {
    show(){
      return this.dialog.show
    },
    valid() {
      let condition = true;
      condition &=this.validate(this.task.going_to,true);
      condition &=this.validate(this.task.meeting_who,true);
      condition &=this.validate(this.task.doing_what,true);
      condition &=this.validate(this.task.product,true);
      condition &=this.validate(this.task.notes,false);
      return condition;
    }
  }
};
</script>

<style>
</style>